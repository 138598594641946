<template>
  <div class="chat-message" :class="'is-from-' + (request.initiator.uuid === message.creator.uuid ? 'user' : 'assignee')">
    <figure class="user-avatar image is-32x32" v-if="!message.creator.avatar">
      <!-- <img class="is-rounded"
        :src="'https://i.pravatar.cc/128?u=' + message.creator.uuid"> -->
      {{ defineName() }}
    </figure>
    <b-image v-else :src="`${this.storageUrl}/avatars/${message.creator.avatar}.png`" alt="" rounded class="is-32x32"></b-image>
    <div class="content">
      <div class="bubble"></div>
      <div class="author">
        {{ message.creator.firstname }} {{ message.creator.lastname }}
      </div>
      <div class="text">{{ message.content }}</div>
      <div class="meta">{{ formatDate(message.created_at) }}</div>
      <div class="file" v-if="message.attachment" @click="downloadFile(message)">
        Télécharger la pièce-jointe
      </div>
    </div>
  </div>
</template>

<script>
import { parseISO, format } from 'date-fns'
import fr from 'date-fns/locale/fr'
// import axios from 'axios'
import { http } from '@/services/api.service'

export default {
  name: 'ChatMessage',
  props: ['message', 'request'],
  data () {
    return {
      storageUrl: process.env.VUE_APP_STORAGE_URL
    }
  },
  methods: {
    downloadFile (message) {
      http.get(`/request/${this.request.uuid}/message/${message.id}/attachment`, { responseType: 'blob' }).then(({ data }) => {
        let fileURL = window.URL.createObjectURL(new Blob([data]))
        let fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', message.attachment)
        document.body.appendChild(fileLink)
        fileLink.click()
      }).catch(() => {
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Nos serveurs rencontrent des difficultés à télécharger ce fichier',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },
    formatDate (date) {
      return format(parseISO(date), "dd/LL/uu '-' HH':'ii", {
        locale: fr
      })
    },
    defineName () {
      if (!this.message.creator) {
        return '??'
      }
      let name = ''
      name = this.message.creator.firstname ? `${this.message.creator.firstname.substr(0, 1)}` : '?'
      name = this.message.creator.lastname ? name + `${this.message.creator.lastname.substr(0, 1)}` : name + '?'
      return name
    }
  }
}
</script>

<style lang="scss">
.chat-message {
  display: flex;
  padding: 10px 0;

  .user-avatar {
    margin: 0 10px 15px;
    align-self: flex-end;
  }
  .content {
    min-width: 200px;
    max-width: 80%;
    position: relative;
  }
  .author {
    font-weight: bold;
    font-size: 12px;
  }
  .text {
    font-size: 12px;
    padding: 4px 10px;
  }
  .meta {
    font-size: 10px;
    color: hsl(0, 0%, 50%);
  }
  .bubble {
    position: absolute;
    width: 0;
    height: 0;
    &:after {
      content: "";
      position: absolute;
      border: 0 solid transparent;
      border-top: 9px solid red;
      border-radius: 0 20px 0;
      width: 15px;
      height: 30px;
      transform: rotate(145deg);
    }
  }

  &.is-from-user {
    .text {
      background-color: hsl(206, 70%, 96%);
      border-radius: 5px 5px 5px 0;
    }
    .meta {
      text-align: right;
    }
    .bubble {
      bottom: 40px;
      left: -14px;
      &:after {
        border-top-color: hsl(206, 70%, 96%);
      }
    }
  }

  &.is-from-assignee {
    flex-direction: row-reverse;
    .text {
      background-color: hsl(142, 52%, 96%);
      border-radius: 5px 5px 0 5px;
    }
    .author {
      text-align: right;
    }
    .bubble {
      bottom: 42px;
      right: 0px;
      &:after {
        transform: rotate(-145deg) scale(-1, 1);
        border-top-color: hsl(142, 52%, 96%);
      }
    }
  }
}
</style>
