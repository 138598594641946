<template>
  <div>
    <form action="">
      <div class="modal-card" style="width: 960px">
        <header class="modal-card-head">
          <p class="modal-card-title">Question</p>
        </header>
        <section class="modal-card-body">
          <b-field label="Selectionner l'opérateur">
            <b-select placeholder="Select a name" v-model="selected">
              <option
                v-for="op in operators"
                :value="op"
                :key="op.uuid">
                {{ op.email }}
              </option>
            </b-select>
          </b-field>
        </section>
        <footer class="modal-card-foot" style="justify-content:flex-end">
          <button class="button" type="button" @click="$parent.close()">
            Annuler
          </button>
          <button class="button is-primary" @click.prevent="transfer">Transférer</button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { http } from '@/services/api.service'
import { socket } from '@/services/socket.service'
export default {
  name: 'TransferModal',
  props: ['request'],
  data () {
    return {
      operators: [],
      selected: null
    }
  },
  mounted () {
    http.get('/user/operator').then(res => {
      this.operators = res.data
    }).catch(err => {
      console.log(err)
    })
  },
  methods: {
    transfer () {
      http.post(`/request/${this.request.uuid}/transfer`, { to: this.selected.uuid }).then(res => {
        socket.emit('request:transferred', res.data)
        socket.emit('unsubscribe', { room: 'chat/' + this.request.uuid })
        this.$emit('transferred', res.data)
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.$parent.close()
      })
    }
  }
}
</script>
